const errorsConstantsPTBR = {
  'client-001': 'Falha na criação do cliente',
  'client-002': 'Falha na criação do cliente. Faltou o E-mail do cliente.',
  'client-003': 'Falha na criação do cliente. Faltou o Nome do cliente.',
  'client-004': 'Falha na criação do cliente. Faltou o Telefone do cliente.',
  'client-005': 'Falha na criação do cliente. Faltou o CPF ou CNPJ do cliente.',
  'client-006': 'Falha na criação do cliente',
  'client-007': 'Falha na criação do cliente',
  'client-008': 'Falha na criação do usuario.',
  'client-009': 'Falha na criação do usuario. Faltou o nome do usuario.',
  'client-010': 'Falha na criação do usuario. Faltou o nome do usuario.',
  'client-011': 'Falha na criação do usuario. Faltou o e-mail do usuario.',
  'client-012': 'Falha na criação das credenciais do usuário.',
  'client-013': 'Falha na criação das credenciais do usuário. Faltou o e-mail do usuario.',
  'client-014': 'Falha na criação das credenciais do usuário. Faltou o tipo de credencial.',
  'client-015': 'Falha na criacao do Usuario. Faltou o perfil do usuario.',
  'client-016': 'Falha na criacao do Usuario. Faltou o perfil do usuario.',
  'client-017': 'Falha na criacao do Usuario. Faltou o parceiro do usuario.',
  'client-018': 'Falha na criacao do Usuario. Faltou o parceiro do usuario.',
  'account-001': 'Falha na requisicao das configuracoes do sistema',
  'account-002': 'Falha na tentativa de acessar o perfil do usuario. Tente logar novamente.',
  'account-003': 'Falha na tentativa de acessar o perfil do usuario. Tente logar novamente.',
  'account-004': 'Falha na tentativa de acessar o perfil do usuario. Tente logar novamente.',
  'account-005': 'Falha na tentativa de acessar o perfil do usuario. Tente logar novamente.',
  'partner-001': 'Falha na criação do Parceiro.',
  'partner-002': 'Falha na criação do Parceiro. Faltou o E-mail do parceiro.',
  'partner-003': 'Falha na criação do Parceiro.',
  'partner-004': 'Falha na criação do Parceiro. Faltou a Tag do parceiro.',
  'partner-005': 'Falha na criação do Parceiro. ',
  'partner-006': 'Falha na criação do Parceiro. Faltou o nome do usuario.',
  'partner-007': 'Falha na criação do Parceiro. Faltou o nome do usuario.',
  'partner-008': 'Falha na criação do Parceiro. Faltou o e-mail do usuario.',
  'partner-009': 'Falha na criação das credenciais do usuário.',
  'partner-010': 'Falha na criação das credenciais do usuário. Faltou o e-mail do usuario.',
  'partner-011': 'Falha na criação das credenciais do usuário. Faltou o tipo de credencial.',
  'partner-012': 'Falha na criação do Usuario. Faltou o perfil do usuario.',
  'partner-013': 'Falha na criação do Usuario. Faltou o perfil do usuario.',
  'request-001': 'Falha na criação do Pedido. Faltou o id do parceiro.',
  'request-002': 'Falha na criação do Pedido. Faltou o id do cliente.',
  'collection-001': 'Falha na criação da Coleta. Faltou a data da coleta.',
  'collection-002': 'Falha na criação da Coleta. Faltou o nome da fazenda.',
  'collection-003': 'Falha na criação da Coleta. Faltou a cultura.',
  'collection-004': 'Falha na criação da Coleta. Faltou o estagio da cultura',
  'collection-005': 'Falha na criação da Coleta. Faltou o cultivar.',
  'collection-006': 'Falha na criação da Coleta. Faltou o tipo de cultivo.',
  'collection-007': 'Falha na criação da Coleta. Faltou o tipo de solo.',
  'collection-008': 'Falha na criação da Coleta. Faltou o id do pedido.',
  'sample-001': 'Falha na criação da Amostra. Faltou o codigo da amostra.',
  'sample-002': 'Falha na criação da Amostra. Faltou o nome da amostra.',
  'sample-003': 'Falha na criação da Amostra. Faltou o tipo da amostra.',
  'sample-004': 'Falha na criação da Amostra. Faltou o id da coleta na amostra.',
  'qrcode-001': 'Falha na criação das etiquetas. Faltou a quantidade de etiquetas.',
  'qrcode-002': 'Falha na criação das etiquetas. Faltou o id do parceiro.',

  // Service Errors
  'client-101': 'Erro. Cliente já existe!',
  'client-102': 'Não foi possível criar a conta de usuário do cliente',
  'client-103': 'Falha ao criar cliente - verifique Nome, CPF, Email ou Telefone',
  'client-104': 'Falha ao encontrar o cliente',
  'client-105': 'Falha ao atualizar o cliente - verifique Nome, CPF, Email ou Telefone',
  'partner-101': 'Falha ao criar parceiro - nome fantasia duplicado',
  'partner-102': 'Falha ao criar parceiro - nome do parceiro duplicado',
  'partner-103': 'Falha ao criar parceiro - usuário duplicado, verifique e-mail',
  'partner-104': 'Falha ao criar parceiro',
  'sample-101': 'Falha ao criar amostra - Falha no ID da coleção',
  'sample-102': 'Falha ao criar metadados de amostra',
  'request-101': 'Não foi possível encontrar o cliente com o id fornecido',
  'request-102': 'Não foi possível encontrar o parceiro com o id fornecido',
  'qrcode-101': 'O QRCode não está disponível no momento',

  'auth-600': 'Falha ao registrar o usuário',
  'auth-601': 'Falha ao criar o compartimento do parceiro',
  'auth-602': 'Falha ao excluir o compartimento do parceiro',
  'auth-603': 'Falha ao obter usuário por e-mail',
  'auth-604': 'Falha ao obter usuários por parceiro (compartimento)',
  'auth-605': 'Falha ao atualizar as informações do usuário',
  'auth-606': 'Falha ao excluir usuário',
  'auth-607': 'Falha ao solicitar alteração de senha',
  'auth-608': 'Falha ao enviar a solicitação de mensagem de boas-vindas',

  // AUTH-SERVICE

  'auth-001': 'Falha no login - Sem e-mail ou senha',
  'auth-002': 'Falha no cadastro - Sem perfil de usuário',
  'auth-003': 'Falha no cadastro - Nenhuma função de usuário',
  'auth-004': 'Falha no cadastro - Nenhum uid de credenciais de usuário',
  'auth-005': 'Falha no cadastro - Usuário não possui um grupo',
  'auth-006': 'Falha no cadastro - Nenhum nome de usuário, sobrenome ou e-mail',
  'auth-007': 'Falha no cadastro - Formato de e-mail inválido',
  'auth-008': 'Falha no cadastro - A senha é diferente da senha de confirmação',

  'auth-100': 'Autorização - O usuário não está logado',
  'auth-101': 'Autorização - O usuário não tem permissão para usar esta operação',
  'auth-102': 'Este usuário não pode alterar as senhas do usuário',

  'auth-200': 'Login - E-mail ou Senha de usuário errado',
  'auth-201':
    'Login - Não é possível usar este serviço para autenticar o usuário - uid com provedor de autenticação externo',
  'auth-202': 'Login - E-mail ou Senha de usuário errado',
  'auth-203': 'Login - Usuário sem perfil',
  'auth-204': 'Login - Usuário não autorizado para o compartimento fornecido',

  'auth-205': 'Credenciais locais não encontradas',
  'auth-206': 'Nenhum usuário com e-mail fornecido',

  'auth-210': 'Cadastro - Este e-mail já foi usado por outra conta',

  'auth-220': 'Conta - A conta já existe, falha ao restaurar a conta',
  'auth-221': 'Conta - Credenciais não informadas',
  'auth-222': 'Conta - Falha ao criar usuario. Email ou CPF duplicado.',
  'auth-223': 'Conta - Falha ao criar nova conta',
  'auth-224': 'Conta - Falha ao criar credenciais do usuário',
  'auth-230': 'A função escolhida para o usuário não existe',

  'tenant-001': 'Nome do compartimento duplicado',

  'user-001': 'ID de usuário desconhecido',
  'user-002': 'Deve fornecer o ID do usuário',
  'user-003': 'Deve fornecer a foto do usuário',

  //REPORTS
  'samplereports-021': 'Erro! Tipo de tabela não corresponde ao domínio do microrganismo',
  'samplereports-022': 'Não foi possível gerar o relatório: O pedido selecionado não possui dados de amostras',
  'samplereports-023': 'Erro! Abundância total diferente de 1'

};

export function errorsPTBR(code: string) {
  return (
    errorsConstantsPTBR[code as keyof typeof errorsConstantsPTBR] || 'Ocorreu um erro! Por favor, tente novamente!'
  );
}
