import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/core/services/auth/config.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(private configService: ConfigService) {
        this.configService.getConfig();
    }

    ngOnInit(): void {}
}
