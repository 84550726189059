import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LocalDatePipe} from "./pipes/local-date.pipe";
import {PhonePipe} from "./pipes/phone.pipe";
import {OutsideClickDirective} from "./directives/outside-click.directive";

@NgModule({
    declarations: [LocalDatePipe, PhonePipe, OutsideClickDirective],
    imports: [CommonModule, HttpClientModule, MatSnackBarModule, MatDialogModule],
    exports: [LocalDatePipe, PhonePipe, OutsideClickDirective],
})
export class CoreModule {}
