export class AccountModel {
    id: number = 0;
    firstname: string = '';
    lastname: string = '';
    email: string = '';
    cpf: string = '';
    rg: string = '';
    birthday: string = '';
    phone: string = '';
    address: string = '';
    city: string = '';
    state: string = '';
    zipcode: string = '';
    country: string = '';
    photo_url: string = '';
    job_type: string = '';
    notification_token: '';
    regional: '';
    created_at: '';
    updated_at: '';
    deleted_at: '';
}
