import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, firstValueFrom, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfigModel } from '../../models/config.model';
import { OPACITY } from '../../utils/contants';
import { SnackbarService } from './../components/snackbar.service';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    private subject = new BehaviorSubject<ConfigModel>(new ConfigModel());
    private state$ = this.subject.asObservable();

    constructor(
        private readonly http: HttpClient,
        @Inject(DOCUMENT) private document: Document,
        private readonly titleService: Title,
        private readonly snackbarService: SnackbarService
    ) {}

    public setState(config: ConfigModel) {
        this.subject.next(config);
    }

    public getState(): Observable<ConfigModel> {
        return this.state$;
    }

    async getConfig() {
        let domain = this.document.location.hostname;
        let subdomain = '';

        if (domain.includes(environment.domainGoSolos)) {
            subdomain = domain.replace(environment.domainGoSolos, '');
            domain = domain.replace(subdomain, '');
            if (subdomain !== '') subdomain = subdomain.replace('.', '');
        }

        let conf = new ConfigModel();

        if (environment.domainGoSolos !== domain) {
            conf.accountType = '';
            const respConfig = await firstValueFrom(
                this.http.get<ConfigModel>(`${environment.apiUrlResource}/api/v1/account/config?domain=${domain}`)
            );

            if (respConfig) {
                conf.primaryColor = respConfig.primaryColor;
                conf.primaryColorOP02 = respConfig.primaryColor + OPACITY.op02;
                conf.primaryColorOP10 = respConfig.primaryColor + OPACITY.op10;
                conf.primaryColorOP30 = respConfig.primaryColor + OPACITY.op30;
                conf.bgLogin = respConfig.primaryColor;
                conf.urlLogo = respConfig.urlLogo;
                conf.domain = domain;
            } else {
                this.snackbarService.showSnackbar('Parceiro não esta cadastrado!', 'danger');
            }
        } else {
            conf.title = 'GoSolos';
            conf.urlLogo = 'assets/logo/logo-gosolos.svg';
            conf.domain = domain;
            conf.primaryColor = '#F68121';
            conf.primaryColorOP02 = '#F68121' + OPACITY.op02;
            conf.primaryColorOP10 = '#F68121' + OPACITY.op10;
            conf.primaryColorOP30 = '#F68121' + OPACITY.op30;

            if (subdomain === 'parceiro' || subdomain === 'dev-parceiro') {
                conf.accountType = 'consultant:partner';
                conf.bgLogin = '#A6D4FF';
            } else if (subdomain === 'cliente' || subdomain === 'dev-cliente') {
                conf.accountType = 'consultant:client';
                conf.bgLogin = '#117C1A';
            } else {
                conf.accountType = 'consultant:admin';
                conf.bgLogin = '#F68121';
            }
        }

        this.setState(conf);
        this.titleService.setTitle(conf.title);
    }

    async getPrimaryColor() {
        return await firstValueFrom(this.getState().pipe(map(t => t.primaryColor)));
    }
}
