import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AccountModel } from '../../models/account.model';

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    private subject = new BehaviorSubject<AccountModel>(new AccountModel());
    private state$ = this.subject.asObservable();

    constructor(private readonly http: HttpClient) {}

    public setState(user: AccountModel) {
        this.subject.next(user);
    }

    public getState(): Observable<AccountModel> {
        return this.state$;
    }

    async getAccount(): Promise<boolean> {
        const accountID = localStorage.getItem('accountID');
        if (!accountID) return false;

        const account = await firstValueFrom(
            this.http.get<AccountModel>(`${environment.apiUrlAuth}/api/v1/user/${accountID}`)
        );
        if (!account) return false;

        this.setState(account);
        return true;
    }
}
