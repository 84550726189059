import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root',
})
export class SnackbarService {
    constructor(private snackBar: MatSnackBar) {}

    public showSnackbar(message: string, type: 'danger' | 'success', duration:number = 6000) {
        let color = `bg-[#22B02E]`;
        if (type == 'danger') color = `bg-[#C5292A]`;

        this.snackBar.open(message, '', {
            duration: duration,
            panelClass: [color, 'font-medium', 'text-white', 'wrap-text'],
            verticalPosition: 'top',
        });
    }
}
