import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ProfileModel } from '../../models/profile.model';
import { SnackbarService } from './../components/snackbar.service';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    public subject = new BehaviorSubject<ProfileModel>(new ProfileModel());
    private state$ = this.subject.asObservable();

    public setState(profile: ProfileModel) {
        this.subject.next(profile);
    }
    public getState(): Observable<ProfileModel> {
        return this.state$;
    }

    constructor(private http: HttpClient, private readonly snackbarService: SnackbarService) {}

    async getProfile(): Promise<boolean> {
        const profile = await firstValueFrom(
            this.http.get<ProfileModel>(`${environment.apiUrlResource}/api/v1/account/profile`)
        );

        if (!profile) {
            this.snackbarService.showSnackbar('Não foi possível carregar o perfil!', 'danger');
            return false;
        }

        this.setState(profile);
        return true;
    }

    async getTenantId() {
        return await firstValueFrom(this.getState().pipe(map(t => t.tenantId)));
    }

    async getScope() {
        return await firstValueFrom(this.getState().pipe(map(t => t.scope)));
    }

    async getRole() {
        return await firstValueFrom(this.getState().pipe(map(t => t.role)));
    }
}
