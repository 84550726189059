import { Injectable } from '@angular/core';
import { errorsPTBR } from '../utils/errorsPTBR';
import { SnackbarService } from './components/snackbar.service';

@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    errors = errorsPTBR;

    constructor(private readonly snackbarService: SnackbarService) {}

    show(code: string) {
        this.snackbarService.showSnackbar(this.errors(code), 'danger');
    }
}
