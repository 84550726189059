import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {SnackbarService} from '../services/components/snackbar.service';
import {AuthService} from './../services/auth/auth.service';
import {ErrorService} from './../services/error.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly snackbarService: SnackbarService,
    private readonly authService: AuthService,
    private readonly errorService: ErrorService
  ) {
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let newReq = req.clone({
      headers: req.headers
        .set('requestfrom', 'admin')
        .set('Authorization', 'Bearer ' + localStorage.getItem('token')),
    });

    return next.handle(newReq).pipe(
      catchError((err: any) => {
        if (err.error.error == 'account-002' || err.error.error == 'auth-101') {
            this.authService.signOut();
        } else {
	    this.errorService.show(err.error.error);
	}


        return throwError(() => new Error(err));
      })
    );
  }

}
