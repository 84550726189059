import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from './../../../environments/environment';
import { StatusRequestModel } from './../models/status-request.model';

@Injectable({
    providedIn: 'root',
})
export class StatusRequestService {
    status: StatusRequestModel[] = [];

    constructor(private readonly http: HttpClient) {}

    async getAll() {
        this.status = await firstValueFrom(
            this.http.get<StatusRequestModel[]>(`${environment.apiUrlResource}/api/v1/status-requests`)
        );
    }
}
