export type TScopes = 'laboratory' | 'partner' | 'consultant' | 'client';
export type TRoles = 'admin' | 'user';

export class ProfileModel {
    accountName: string = '';
    accountID: number = 0;
    scope: TScopes = 'client';
    role: TRoles = 'user';
    tenantId: number = 0;
}
