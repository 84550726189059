export class ConfigModel {
    primaryColor: string = '';
    primaryColorOP02: string = '';
    primaryColorOP10: string = '';
    primaryColorOP30: string = '';
    bgLogin: string = '';
    urlLogo: string = '';
    title: string = '';
    domain: string = '';
    accountType: TAccountType = 'consultant:admin';
}

export type TAccountType = 'consultant:admin' | 'consultant:partner' | 'consultant:client' | '';
