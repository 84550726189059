export const maiorPotencialProdutivo = 'Maior Potencial Produtivo';
export const menorPotencialProdutivo = 'Menor Potencial Produtivo';

export const SCOPES = {
    laboratory: 'laboratory',
    partner: 'partner',
    consultant: 'consultant',
    client: 'client',
};

export const questionOptions = ['Sim', 'Não', 'Não informado'];

export const OPACITY = {
    op02: '05',
    op10: '1A',
    op30: '4D',
};

export const arrayAllStates: string[] = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
];
