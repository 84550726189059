import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ProfileService } from 'src/app/core/services/auth/profile.service';
import { environment } from 'src/environments/environment';
import { CredentialsModel } from '../../models/credentials.model';
import { StatusRequestService } from '../status-request.service';
import { AccountService } from './account.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    authenticated: boolean = false;
    token: undefined | null | string;
    accountID: undefined | null | string;

    constructor(
        private readonly router: Router,
        private readonly http: HttpClient,
        private readonly profileService: ProfileService,
        private readonly accountService: AccountService,
        private readonly status: StatusRequestService
    ) {}

    async signIn(credentials: CredentialsModel) {
        const auth = await firstValueFrom(this.http.post<any>(`${environment.apiUrlAuth}/auth/login`, credentials));
        if (!auth) this.signOut();
        else {
            this.accountService.setState(auth.user);
            this.authenticated = true;
            localStorage.setItem('token', auth.token);
            localStorage.setItem('accountID', auth.user.id);
            const profile = await this.profileService.getProfile();
            if (profile) {
                const entry_path = localStorage.getItem('entry_path');
                if (entry_path) {
                    this.router.navigateByUrl(entry_path);
                    localStorage.removeItem('entry_path');
                } else this.router.navigate(['']);

                this.loadData();
            } else this.signOut();
        }
    }

    signOut() {
        this.authenticated = false;
        this.clearLocalStorage();
        this.router.navigate(['/login']);
    }

    loadData() {
        this.status.getAll();
    }

    clearLocalStorage() {
        localStorage.removeItem('token');
        localStorage.removeItem('accountID');
    }

    setPassword(credentials: CredentialsModel, token: string) {
        localStorage.setItem('token', token);
        let request = this.http.post(`${environment.apiUrlAuth}/auth/setpass`, credentials);
        request.subscribe(() => {
            localStorage.removeItem('token');
        });
        return request;
    }

    forgotPassword(credentials: CredentialsModel) {
        return this.http.post(`${environment.apiUrlAuth}/auth/forgotpass`, credentials);
    }

    private async reAuthentication(): Promise<boolean> {
        const token = localStorage.getItem('token');

        if (!token) {
            localStorage.setItem('entry_path', window.location.pathname + window.location.search);
            this.signOut();
            return false;
        }

        if ((await this.profileService.getProfile()) && (await this.accountService.getAccount())) {
            this.authenticated = true;
            this.loadData();
            return true;
        }

        this.signOut();
        return false;
    }

    async checkAuthenticated(): Promise<boolean> {
        if (this.authenticated) return true;
        else return await this.reAuthentication();
    }
}
